import {
  APINotificationInterface,
  NotificationInterface,
  UpdateNotificationRequestInterface
} from '@partnerconnect-monorepo/types';
import { axios } from '../core';
import { mapToCamelCase, mapToSnakeCase } from '../helpers';
import {
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery
} from 'react-query';
import { AxiosError } from 'axios';
import { mapFrequencyToOptionElement } from '../utils';

const getNotifications = (moduleUrl: string) =>
  axios
    .get(`${moduleUrl}/notifications`)
    .then<Array<APINotificationInterface>>((apiNotifications) =>
      mapToCamelCase(apiNotifications.data)
    )
    .then<Array<NotificationInterface>>((notifications) =>
      notifications.map(({ frequency, allowedFrequencies, ...otherProps }) => ({
        ...otherProps,
        frequency: mapFrequencyToOptionElement(frequency),
        allowedFrequencies: allowedFrequencies.map((frequency) =>
          mapFrequencyToOptionElement(frequency)
        )
      }))
    );

export const useReportingModuleNotifications = (
  options: UseQueryOptions<
    Array<NotificationInterface>,
    AxiosError,
    Array<NotificationInterface>,
    [string]
  > = {}
) =>
  useQuery(
    ['REPORTING_MODULE_NOTIFICATIONS'],
    () => getNotifications(process.env.NX_REPORTING_APP_BE_BASE ?? ''),
    options
  );

export const usePartnershipsModuleNotifications = (
  options: UseQueryOptions<
    Array<NotificationInterface>,
    AxiosError,
    Array<NotificationInterface>,
    [string]
  > = {}
) =>
  useQuery(
    ['PARTNERSHIPS_MODULE_NOTIFICATIONS'],
    () => getNotifications(process.env.NX_FLA_APP_BE_BASE ?? ''),
    options
  );

const updateNotification = ({
  module,
  code,
  ...payloadProps
}: UpdateNotificationRequestInterface) =>
  axios
    .put(
      `${
        module === 'reporting'
          ? process.env.NX_REPORTING_APP_BE_BASE
          : process.env.NX_FLA_APP_BE_BASE
      }/notifications/${code}/`,
      mapToSnakeCase(payloadProps)
    )
    .then<APINotificationInterface>((apiNotification) =>
      mapToCamelCase(apiNotification.data)
    )
    .then<NotificationInterface>(
      ({ frequency, allowedFrequencies, ...otherProps }) => ({
        ...otherProps,
        frequency: mapFrequencyToOptionElement(frequency),
        allowedFrequencies: allowedFrequencies.map((frequency) =>
          mapFrequencyToOptionElement(frequency)
        )
      })
    );

export const useUpdateNotification = (
  options: UseMutationOptions<
    NotificationInterface,
    unknown,
    UpdateNotificationRequestInterface
  > = {}
) => useMutation(updateNotification, options);
