import { T } from '@transifex/react';
import { Loading, Text, Toggle } from '@wfp/ui';
import { Row, Col } from 'react-flexbox-grid';
import { Control, Controller } from 'react-hook-form';
import {
  NotificationInterface,
  OptionElement
} from '@partnerconnect-monorepo/types';
import Select from 'react-select';
import { v4 as uuid } from 'uuid';
import { getNotificationLabel } from '../../../../utils';

interface NotificationViewProps {
  control: Control<NotificationInterface, any>;
  frequencyOptions: Array<OptionElement>;
  isLoading: boolean;
}

export const NotificationView = ({
  control,
  frequencyOptions,
  isLoading
}: NotificationViewProps) => (
  <>
    {isLoading && <Loading />}
    <Row middle="xs">
      <Col>
        <Controller
          control={control}
          name="enabled"
          render={({ field }) => (
            <Toggle
              id={uuid()}
              toggled={field.value}
              onToggle={field.onChange}
              labelA=""
              labelB=""
            />
          )}
        />
      </Col>
      <Col xs>
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <Text>{getNotificationLabel(field.value)}</Text>
          )}
        />
      </Col>
      <Col xs={4}>
        <Controller
          control={control}
          name="frequency"
          render={({ field }) => (
            <Select
              {...field}
              placeholder={<T _str="Click to select" />}
              className="wfp--react-select-container"
              classNamePrefix="wfp--react-select"
              value={field.value}
              options={frequencyOptions}
              isDisabled={frequencyOptions.length === 1}
            />
          )}
        />
      </Col>
    </Row>
  </>
);
