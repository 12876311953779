import { T, UT } from '@transifex/react';
import { Button, Hero, Text, Wrapper } from '@wfp/ui';
import { Col, Grid, Row } from 'react-flexbox-grid';

interface LandingTopHeroTypes {
  signInRedirect: () => void;
}

export const LandingTopHero = ({ signInRedirect }: LandingTopHeroTypes) => {
  return (
    <Hero
      id="landing-page-hero"
      image="../../assets/landingTopHero.webp"
      kind="splash-image"
      title={
        <Wrapper pageWidth="lg" mobilePageWidth="full">
          <Grid>
            <Row middle="md" className="row">
              <Col sm={12} md={6}>
                <div id="hero-content-block">
                  <Text kind="h1">
                    <UT _str="All in one <span>partnership management</span> system" />
                  </Text>
                  <Text kind="subtitle">
                    <T _str="The gateway between WFP and cooperating partners" />
                  </Text>
                  <Button className="login-button" onClick={signInRedirect}>
                    <T _str="Log in or register" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Grid>
        </Wrapper>
      }
    />
  );
};
