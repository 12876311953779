import {
  usePartnershipsModuleNotifications,
  useReportingModuleNotifications
} from '../../../api';

export const useNotifications = () => {
  const {
    data: reportingModuleNotifications,
    isLoading: isLoadingReportingModuleNotifications
  } = useReportingModuleNotifications();

  const {
    data: partnershipsModuleNotifications,
    isLoading: isLoadingPartnershipsModuleNotifications
  } = usePartnershipsModuleNotifications({ enabled: false });

  return {
    reportingModuleNotifications: reportingModuleNotifications?.sort(
      (notificationA, notificationB) =>
        notificationA.code.localeCompare(notificationB.code)
    ),
    partnershipsModuleNotifications: partnershipsModuleNotifications?.sort(
      (notificationA, notificationB) =>
        notificationA.code.localeCompare(notificationB.code)
    ),
    isLoading:
      isLoadingReportingModuleNotifications ||
      isLoadingPartnershipsModuleNotifications
  };
};
